import { Footer } from "./Footer";
import { styled } from "styletron-react";

const Container = styled("div", {
  margin: "3rem",
});

const Summary = styled("p", {
  marginTop: "1rem",
  marginBottom: "0.5rem",
  marginRight: "1rem",
});

const PrivacyPolicy = () => {
  return (
    <>
      <Container>
        <h2>Privacy Policy</h2>
        <Summary>
          This privacy policy sets out how vaxx.nz (“vaxx.nz”) collects,
          processes and uses your Personal Information through your use of our
          Services.
        </Summary>
        <br />
        <h3>Definitions</h3>
        <p>
          <br />
          <strong>Anonymized Data</strong> means anonymous information collected
          by us through your use of this Website or our Services for statistical
          and/or demographic purposes. Anonymized Data may be derived from
          Personal Information.
          <br />
          <br />
          <strong>Data Processor</strong> means each of the following: Github;
          Google Analytics; and Netlify.
          <br />
          <br />
          <strong>DPO</strong> means Data Protection Officer, and is the person
          specifically appointed by us to deal with your enquiries with respect
          to this privacy policy and your Personal Information.
          <br />
          <br />
          <strong>Personal Information</strong> means any identifying
          information about you. This includes, but is not limited to, the
          following: cookies and usage data.
          <br />
          <br />
          <strong>Services</strong> means all services provided by vaxx.nz to
          you, including (but not limited to) this Website and the following:
          Delivery of product and service.
          <br />
          <br />
          <strong>Website</strong> means vaxx.nz and all websites, applications
          and communications that post a link to this Privacy Policy.
          <br />
        </p>
        <br />
        <h3>Personal Information Collection</h3>
        <p>
          <br />
          We only collect and use Personal Information to the extent necessary
          to provide you with the Services. We collect Personal Information for
          the Website to provide you with a better online experience.
          <br />
          We also provide the following services:
          <br />
          <br />
          <strong>Delivery of product and service</strong>
          <br />
          <br />
          To deliver the Services, fulfill and manage customer orders and
          provide customer support.
        </p>
        <br />
        <h3>Personal Information Retention</h3>
        <p>
          <br />
          We keep Personal Information for the shortest time necessary to
          provide you with Services and to meet all our legal and compliance
          obligations. To determine this retention period, we take into account
          <br />
          &nbsp;(i) the nature of the Personal Information gathered; and <br />
          &nbsp;(ii) the nature of our legal and compliance obligations. All
          Personal Information no longer required by us is destroyed and/or
          erased.
          <br />
          <br />
        </p>
        <h3>Anonymized Data</h3>
        <p>
          <br />
          We may use, collect and share Anonymized Data for any purpose at our
          discretion. Anonymized Data cannot be used to reveal your identity
          (either directly or indirectly). We may retain all Anonymized Data
          indefinitely. Anonymized Data includes:
          <br />
          <ul>
            <li>
              Device data: This includes information about the device used to
              interact with our Services.
            </li>
            <li>
              Location data: This includes geolocation data (based on your IP
              address).
            </li>
            <li>
              Log and usage data: This includes usage and performance
              information with respect to our Services.
            </li>
          </ul>
        </p>
        <br />
        <h3>Your Rights</h3>
        <p>
          <br />
          With respect to Personal Information we hold, you have the following
          rights:
          <br />
          <ul>
            <li>
              <strong>Access: </strong>You may request from us access to your
              data that we hold on you.
            </li>
            <li>
              <strong>Rectification: </strong>If the data we hold on you is
              inaccurate, you may request that we correct it. If the data we
              hold is incomplete, you may request that we complete it.
            </li>
            <li>
              <strong>Erasure: </strong>Subject to certain conditions, you may
              request that we erase all of the data we hold on you.
            </li>
            <li>
              <strong>Restrictions: </strong>Subject to certain conditions, you
              may request that we restrict the processing of data we hold on
              you.
            </li>
            <li>
              <strong>Portability: </strong>Subject to certain conditions, you
              may request that we transfer all the data we hold on you to a
              third party (including yourself).
            </li>
            <li>
              <strong>Objections: </strong>Subject to certain conditions, you
              may object to us processing the data we hold on you.
            </li>
          </ul>
        </p>
        <h3>Data Processors</h3>
        <p>
          <br />
          We engage Data Processors to assist us in providing you with Services.
          Data Processors are contractually obligated to us, and must:
          <br />
          <ul>
            <li>
              comply with, and act only on, our instructions with respect to the
              data that they process on our behalf (we, of course, give our
              instructions in accordance with this privacy policy);
            </li>
            <li>
              notify us if they believe we are acting in breach of the GDPR
              (where applicable);
            </li>
            <li>
              first obtain our consent prior to sub-contracting data processing
              services;
            </li>
            <li>
              comply with their obligations under the GDPR (where applicable).
            </li>
          </ul>
          <br />
        </p>
        <h3>Social Media</h3>
        <p>
          <br />
          You may choose to engage with our Services through a variety of social
          media sites and platforms, for example Facebook, Instagram and
          Twitter. When you interact with our Services through these social
          media platforms, you may allow us to receive Personal Information and
          other Anonymized Data on how you are interacting with our Services.
          This will depend on your privacy settings on each social media site
          and platform.
          <br />
          The data we are provided by you through your interaction with our
          Services on social media sites and platforms will be used by us
          subject to the terms of this privacy policy. Depending on your social
          media site’s privacy settings, your Personal Information may become
          public to a community of users if you choose to engage with our
          Services through these platforms.
          <br />
          For further information on how you can customize your privacy settings
          on social media sites and how those sites handle your privacy, please
          refer to the privacy policy of each particular social media site.
          <br />
          <br />
        </p>
        <h3>Minors</h3>
        <p>
          <br />
          We do not knowingly collect information on children under the age of
          16 (“Child”). If you become aware that a Child has provided us with
          Personal Information, please contact us. If we become aware that we
          have collected Personal Information from a Child without their
          parent's verifiable authorization to access our Services, we will take
          steps to remove that information from our servers.
          <br />
          <br />
        </p>
        <h3>Third Party Links</h3>
        <p>
          <br />
          Our website contains links to other third-party websites not owned or
          managed by vaxx.nz. This privacy policy applies to this website only.
          If you click a link to a third-party website, the privacy policy of
          that website will apply. We highly recommend that you read the privacy
          policies of other websites as they may be different from ours.
          <br />
          <br />
        </p>
        <h3>Updates</h3>
        <p>
          <br />
          We are constantly reviewing our privacy policy and procedures to
          ensure that they meet best practices. This policy was last updated on
          September 21, 2021.
          <br />
          <br />
        </p>
        <h3>Contact Us</h3>
        <p>
          <br />
          If you have any questions or suggestions about our privacy policy or
          want to know more information about Personal Information we hold,
          please contact us at info@vaxx.nz.
          <br />
          <br />
        </p>
        <h3>Complaints</h3>
        <p>
          <br />
          If you believe that we have breached our obligations to you under this
          privacy policy or relevant privacy laws, please contact us at
          info@vaxx.nz.
          <br />
          If you are not satisfied with our response, you have the right to
          lodge a complaint at any time to your relevant authority.
          <br />
          <br />
        </p>
      </Container>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
