import { Footer } from "./Footer";
import { styled } from "styletron-react";

const Container = styled("div", {
  margin: "3rem",
});

const Summary = styled("p", {
  marginTop: "1rem",
  marginBottom: "0.5rem",
  marginRight: "1rem",
});

const TermsAndConditions = () => {
  return (
    <>
      <Container>
        <h2>Terms and Conditions</h2>
        <Summary>
          Effective as of September 21, 2021.
          <br />
          <br />
          This agreement constitutes a binding legal contract between
          vaxx.nz(“vaxx.nz”) (sometimes referred to as “we”, “us” or “our”) and
          you, with respect to your use of https://vaxx.nz and all websites,
          applications and communications that post a link to this agreement
          (collectively, the “Site”)(“Terms and Conditions”). We reserve our
          right to change these Terms and Conditions in the future. Except as
          explicitly stated otherwise, your continued use of the Site will
          constitute deemed acceptance of our updated Terms and Conditions.
        </Summary>
        <br />
        <h3>Site</h3>
        <p>
          <br />
          (a) We may from time to time, at our sole and absolute discretion and
          without notice to you, update the Site (or any part of it).
          <br />
          <br />
          (b) We will not be liable to you or any third party for any
          modification, variation, interruption, suspension or discontinuation
          of the Site. The Site may provide links to third party websites that
          are not owned, managed or controlled by us. You expressly acknowledge
          and agree that we are not responsible for the content of those third
          party websites. You are encouraged to read that third party’s terms
          and conditions, privacy policy and any other document that governs
          your relationship with that third party.
          <br />
          <br />
          (c) The Site uses cookies in accordance with our Cookies Policy, which
          can be found here:&nbsp;
          <a href="./cookie-policy">Cookies Policy.</a>
          <br />
        </p>
        <br />
        <h3>Prohibited Uses</h3>
        <p>
          Unless explicitly agreed by us, you acknowledge and agree that you may
          not use our Site for the following purposes:
          <ul>
            <li>
              use the Site in any way that violates any applicable local,
              national or international law or regulation
            </li>
            <li>impersonate or attempt to impersonate any person or entity</li>
            <li>
              engage in any conduct that restricts or inhibits anyone’s use or
              enjoyment of the Site
            </li>
            <li>
              attempt to gain unauthorized access to, interfere with, damage or
              disrupt any parts of the Site
            </li>
            <li>
              use the Site in any manner that could disable, overburden, damage,
              or impair the Site
            </li>
            <li>
              attempt to interfere with the proper working of the Site in any
              way whatsoever
            </li>
          </ul>
          <br />
          <br />
          <h3>Specifications</h3>
          <p>
            <br />
            (a) We may from time to time, at our sole and absolute discretion
            and without notice to you, update the Site (or any part of it).
            <br />
            <br />
            (b) We may from time to time, at our sole discretion and without
            notice to you, make variations, modifications, alterations or
            updates to our Site (“Enhancements”). These Enhancements may be made
            to improve our Site to you or to comply with relevant legal
            requirements.
            <br />
            <br />
            (c) We will use reasonable endeavors to notify you of any scheduled
            disruptions to our Site, including those due to any Enhancements.
            However, regardless of whether a disruption is scheduled or not, we
            are not liable to you or any third party for any loss or damage
            caused by any disruption to our Site.
            <br />
            <br />
          </p>
        </p>
        <h3>Copyrights and Trademarks</h3>
        <p>
          <br />
          (a) Except as expressly stated otherwise, you acknowledge and agree
          that the original content on the Site, and the software, features and
          functionality comprising the Site are the exclusive property of
          Vaxx.nz (the “Materials”) and its licensors.
          <br />
          <br />
          (b) You agree you will not copy, reproduce, create derivative works
          from, transmit or distribute the Materials in any way without our
          prior written consent.
          <br />
          <br />
        </p>
        <br />
        <h3>Limitation of Liability, Indemnities and Warranties</h3>
        <p>
          <br />
          (a) Except as expressly provided by law, we shall not be liable to you
          or any third parties for any loss, damage, expenses or any other
          liability arising directly or indirectly from the performance of our
          services to you. To the fullest extent permitted by law, all
          warranties or conditions implied by statute, at law, by trade, custom
          or otherwise are excluded.
          <br />
          <br />
          (b) You acknowledge and agree that you assume sole and entire
          responsibility for, and indemnify and hold us harmless from, any and
          all claims, liabilities, losses, expenses, responsibilities and
          damages by reason of any claim, proceedings, action, liability or
          injury arising out of or as a result of <br />
          &nbsp;(i) your conduct in relation to these Terms and Conditions;{" "}
          <br />
          &nbsp;(ii) your use of any material, advice or other results of the
          services provided to you; <br />
          &nbsp;(iii) your relations with your clients and/or other third
          parties; or <br />
          &nbsp;(iv) any breach of these Terms and Conditions by you.
          <br />
          <br />
        </p>
        <h3>Term and Termination</h3>
        <p>
          <br />
          (a) These Terms and Conditions will remain in full force and effect
          while you use the Site.
          <br />
          <br />
          (b) We may, without notice to you, immediately stop providing any
          services to you at our sole discretion for any reason whatsoever.
          <br />
          <br />
          (c) Where these Terms and Conditions are terminated in accordance with
          these Terms and Conditions, they will terminate without prejudice to
          any rights either party may have had against the other prior to
          termination. Further, all provisions of which by their nature should
          survive termination shall survive termination, including, without
          limitation: <br />
          &nbsp;(i) all intellectual property you have granted us under these
          Terms and Conditions; and <br />
          &nbsp;(ii) your obligation to pay any fees due to us at the time of
          termination; and <br />
          &nbsp;(iii) warranty disclaimers, indemnity and limitations of
          liability.
          <br />
          <br />
        </p>
        <h3>Assignment</h3>
        <p>
          <br />
          (a) These Terms and Conditions will bind and inure for the benefit of
          the parties, including their respective successors, permitted assigns
          and legal representatives.
          <br />
          <br />
          (b) We may, without notice to you, immediately stop providing any
          services to you at our sole discretion for any reason whatsoever.
          <br />
          <br />
          (c) Provided your rights are not affected under these Terms and
          Conditions, we will be permitted to assign our interest in these Terms
          and Conditions without prior notice to you. However, you may not
          assign, transfer, novate or other dispose of your rights and
          obligations under these Terms and Conditions without our express
          written approval (which we may withhold in our sole discretion).
          <br />
          <br />
        </p>
        <h3>Privacy</h3>
        <p>
          <br />
          (a) You acknowledge that you have read and understood the terms of
          vaxx.nz's Privacy Policy, which can be found here:&nbsp;
          <a href="privacy-policy">Privacy Policy.</a>
          <br />
          <br />
          (b) You agree that we may use your information (including disclosure
          to third parties) in accordance with the terms of our Privacy Policy.
          <br />
          <br />
        </p>
        <h3>Non-Waiver</h3>
        <p>
          <br />
          Our failure to exercise, or delay in exercising, our rights under
          these Terms and Conditions does not operate as a waiver of that right.
          <br />
          <br />
        </p>
        <h3>Exclusion of Implied Relationships</h3>
        <p>
          <br />
          Nothing in these Terms and Conditions shall be deemed or construed to
          constitute any party a partner, agent, representative, employer or
          employee of another party or to create any trust or commercial
          partnership unless specifically otherwise provided. We do not owe you
          any duty of good faith under these Terms and Conditions.
          <br />
          <br />
        </p>
        <h3>Severability</h3>
        <p>
          <br />
          If any part of these Terms and Conditions are held to be illegal,
          invalid, or unenforceable, then that part shall be deemed deleted and
          shall not affect the validity and enforceability of the remaining
          provisions of these Terms and Conditions.
          <br />
          <br />
        </p>
        <h3>Entire Agreement</h3>
        <p>
          <br />
          These Terms and Conditions constitute the entire agreement between the
          parties with respect to the Company’s services and supersede all
          previous versions of the Terms and Conditions, understandings,
          arrangements, agreements, and communications, whether verbal or
          written, between the parties.
          <br />
          <br />
        </p>
        <h3>Applicable Law and Jurisdiction</h3>
        <p>
          <br />
          These Terms and Conditions shall be governed by and construed in
          accordance with N/A law. Both parties agree to submit to the
          jurisdiction of the courts of N/A with respect to any claim or dispute
          arising out of these Terms and Conditions.
          <br />
          <br />
        </p>
      </Container>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
